import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { GetStoreByIdResponse } from "@switcherstudio/switcher-api-client";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux"
import { RootState } from "store/reducers"

export const useOnboardingRedirect = () => {
    const shopState = useSelector((s: RootState) => s.shop);
    const navigation = useSelector((s: RootState) => s.navigation);
    const host = navigation?.host;
    const currentPage = window.location.pathname;
    const redirectFromStatus = useCallback((shopInfo: GetStoreByIdResponse) => {
        const onboardingStatus = shopInfo?.onboardingStatus as unknown;
        const hasTrialedOrhasActivePlan = shopInfo?.trialData?.hasTrialed || shopInfo?.connectedShopUser?.Roles.includes("Merchant")
        const app = createApp({
            apiKey: process.env.REACT_APP_SHOPIFY_CLIENTID,
            host: host,
        });

        const redirectToPage = (targetPage: string) => {

            if (currentPage !== targetPage) {
                redirect.dispatch(Redirect.Action.APP, targetPage);
            }
        }
        const redirect = Redirect.create(app);
        switch (onboardingStatus) {
            case "SHOP_AWAITING_SUBSCRIPTION_AWAITING_USER_CONNECTION":
                if (hasTrialedOrhasActivePlan) {
                    redirectToPage("/accounts");
                } else {
                    redirectToPage("/welcome")
                }
                break;
            case "SHOP_AWAITING_SUBSCRIPTION_USER_CONNECTED":
                if (hasTrialedOrhasActivePlan) {
                    redirectToPage("/accounts");
                } else {
                    redirectToPage("/choose-plan");
                }
                break;
            case "SHOP_TRIALING_AWAITING_USER_CONNECTION":
            case "SHOP_TRIALING_USER_CONNECTED":
            case "SHOP_SUBSCRIBED_AWAITING_USER_CONNECTION":
                redirectToPage("/accounts");
                break;
            case "SHOP_SUBSCRIBED_USER_CONNECTED":
                if(currentPage === "/" || currentPage === "/welcome" || currentPage === "/choose-plan")
                    redirectToPage("/accounts");
                break;
            default:
                break;
        }
    }, [host, currentPage]);

    useEffect(() => {
        if (shopState?.shopInfo) {
            redirectFromStatus(shopState.shopInfo);
        }
    }, [shopState, redirectFromStatus]);
}
