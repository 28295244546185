import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const eventMap = {
  "/welcome": "Shopify App Installed",
  "/accounts": "Accounts viewed",
  "/analytics": "Analytics viewed",
};

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.load(process.env.REACT_APP_SEGMENT_WRITEKEY);
  }, [])

  useEffect(() => {
    analytics.page(pathToPage(location.pathname));
    analytics.track(eventMap[location.pathname]);
  }, [location.pathname]);
};

const pathToPage = (pathname: string): string => {
  const word = pathname.split('/')[1];
  return word.slice(0, 1).toLocaleUpperCase() + word.slice(1)
}