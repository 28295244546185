import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { Layout, Card } from "@shopify/polaris";
import { useGetCollections } from "hooks/useGetCollections";
import React, { useCallback } from "react";
import { SkeletonCard } from "../SkeletonCard";
import { CollectionsCardContent } from "./CollectionsCardContent";

export interface CollectionsCardProps {
  host: string;
}

export const CollectionsCard: React.FC<CollectionsCardProps> = ({ host }) => {
  const { collections, productCount, loading } = useGetCollections();

  const toAdminPage = useCallback(
    (path: string) => {
      const app = createApp({
        apiKey: process.env.REACT_APP_SHOPIFY_CLIENTID,
        host: host,
      });

      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.ADMIN_PATH, {
        path,
        newContext: true,
      });
    },
    [host]
  );

  return (
    <Layout.AnnotatedSection
      title="Choose Collections"
      description="Choose which Shopify collections you'd like to feature in your livestreams. Those products' graphics will then be available in the Cartr tab of your Switcher iOS app to highlight in your videos."
    >
      <Card
        sectioned
        title="Collection availability"
        actions={[
          {
            content: "Manage availability",
            onAction: () => toAdminPage(`/products/inventory`),
          },
        ]}
      >
        {loading ? (
          <SkeletonCard />
        ) : (
          <CollectionsCardContent
            loading={loading}
            collections={collections}
            productCount={productCount}
            toAdminPage={toAdminPage}
          />
        )}
      </Card>
    </Layout.AnnotatedSection>
  );
};
