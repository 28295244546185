import React, { useEffect, useMemo } from "react";

import { RootState } from "store/reducers";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { RedirectPage } from "pages/RedirectPage";
import { setHost, setLocale, setShop } from "store/navigation/slice";
import { client } from "api/client";
import { getShopInfo } from "store/shop/thunks";
import { Route, Routes } from "react-router-dom";
import { WelcomePage } from "pages/WelcomePage";
import { AccountsPage } from "pages/AccountsPage";
import { AnalyticsPage } from "pages/AnalyticsPage";
import { useAccountConnection } from "hooks/useAccountConnection";
import { LivestreamDetailPage } from "pages/LivestreamDetailPage.tsx";
import { usePageTracking } from 'hooks/usePageTracking';
import { ChoosePlanPage } from "pages/ChoosePlanPage";
import { Frame, Loading } from "@shopify/polaris";
import { useOnboardingRedirect } from "hooks/useOnboardingRedirect";

export function App() {
  usePageTracking();
  useOnboardingRedirect();
  
  const dispatch = useDispatch<AppDispatch>();

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const host = urlParams.get("host");
  dispatch(setHost(host));
  const shop = urlParams.get("shop");
  dispatch(setShop(shop));
  const chargeId = urlParams.get("charge_id");
  const locale = urlParams.get("locale");
  dispatch(setLocale(locale));


  const isTopFrame = window.top === window.self;

  const userState = useSelector((s: RootState) => s.user);
  const shopState = useSelector((s: RootState) => s.shop);
  const {
    connected,
    connectSwitcherAccount,
    disconnectSwitcherAccount,
    shouldShowAccountConnectionSuccessBanner,
    shouldShowAccountConnectionErrorBanner,
  } = useAccountConnection(shop, host, isTopFrame, urlParams, userState, shopState);
  
  useEffect(() => {
    const updateSubscription = async () => {
      if (chargeId) {
        await client.shopifySubscriptions_PutShopifySubscription(
          shop,
          `gid://shopify/AppSubscription/${chargeId}`,
          true
        );
        dispatch(getShopInfo(shop));
      }
    };
    updateSubscription();
  }, [chargeId, shop, dispatch]);

  useEffect(() => {
    dispatch(getShopInfo(shop));
  }, [dispatch, shop]);

  if (isTopFrame && process.env.REACT_APP_ENV !== "local") {
    return <RedirectPage />;
  }

  if (!shopState.shopInfo) return <Frame><Loading /></Frame>

  return (
    <Routes>
      <Route
        path="/accounts"
        element={
          <AccountsPage
            connected={connected}
            connectSwitcherAccount={connectSwitcherAccount}
            disconnectSwitcherAccount={disconnectSwitcherAccount}
            shouldShowAccountConnectionSuccessBanner={
              shouldShowAccountConnectionSuccessBanner
            }
            shouldShowAccountConnectionErrorBanner={
              shouldShowAccountConnectionErrorBanner
            }
          />
        }
      />
      <Route
        path="/welcome"
        element={
          <WelcomePage connectSwitcherAccount={connectSwitcherAccount} />
        }
      />
      <Route
        path="/choose-plan"
        element={
          <ChoosePlanPage />
        }
      />
      <Route path="/analytics" element={<AnalyticsPage />} />
      <Route path="/analytics/:id" element={<LivestreamDetailPage />} />
    </Routes>
  );
}
