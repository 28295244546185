import React from "react";
import { Button, Card, DisplayText, Stack } from "@shopify/polaris";
import styles from "./styles.module.css";
import { Plan } from "helpers/subscriptionPlans";
import { ReactComponent as Checkmark } from "assets/icons/Checkmark.svg";

export const ChoosePlanCard = ({
  createAppSubscription,
  createAppSubscriptionLoading,
  createdAppSubscriptionType,
  userHasSwitcherSubscription,
  plan,
  plan: { name, formattedPrice, salesFee, features },
  trialDays,
}: {
  createAppSubscription: (plan: Plan) => void;
  createAppSubscriptionLoading: boolean;
  createdAppSubscriptionType: Plan["name"];
  plan: Plan;
  userHasSwitcherSubscription: boolean;
  trialDays: number;
}) => (
  <Card>
    <div className={styles["choose-plan-card-content"]}>
      <div className={styles["price-and-action-container"]}>
        <DisplayText size="small">{name}</DisplayText>
        <div className={styles["base-price-line"]}>
          <DisplayText size="large">{formattedPrice}</DisplayText>
          <p>/month</p>
        </div>
        {salesFee && (
          <strong>{`+${salesFee} sales fee`}</strong>
        )}
        <Button
          primary
          loading={
            createAppSubscriptionLoading && createdAppSubscriptionType === name
          }
          disabled={
            createAppSubscriptionLoading && createdAppSubscriptionType !== name
          }
          onClick={() => createAppSubscription(plan)}
        >
          {userHasSwitcherSubscription
            ? `Choose ${name}`
            : `Start ${trialDays}-day trial`}
        </Button>
      </div>
      <div className={styles["features-container"]}>
        {features.map((feature) => (
          <div className={styles["feature"]}>
            <Stack>
              <Checkmark />
              <p>{feature}</p>
            </Stack>
          </div>
        ))}
      </div>
    </div>
  </Card>
);
