import {
  Button,
  Card,
  Collapsible,
  Stack,
  TextStyle,
  List,
  Link,
  TextContainer,
} from "@shopify/polaris";
import React, { useState } from "react";
import DownloadSwitcher from "assets/icons/getting-started-checklist/DownloadSwitcher.png";
import ProductCollections from "assets/icons/getting-started-checklist/ProductCollections.png";
import CartrTab from "assets/icons/getting-started-checklist/CartrTab.png";
import { ReactComponent as UpIcon } from "assets/icons/Up.svg";
import { ReactComponent as DownIcon } from "assets/icons/Down.svg";
import { ToDoItem } from "./types";
import { GetStartedChecklistItem } from "./GetStartedChecklistItem";

export function GetStartedChecklist() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const toDoItems: ToDoItem[] = [
    {
      icon: <img src={DownloadSwitcher} alt="Download Switcher" />,
      title: "Download Switcher Studio App",
      description: (
        <TextContainer>
          Make sure to download the Switcher Studio iOS app on your iPhone or
          iPad if you haven't already done so. Visit the{" "}
          <Link url={process.env.REACT_APP_APP_STORE_LINK} external>
            Apple App Store
          </Link>{" "}
          to download the app for free.
        </TextContainer>
      ),
    },
    {
      icon: <img src={ProductCollections} alt="Product Collections" />,
      title: "Choose product collections to feature in your streams",
      description: (
        <TextContainer>
          Share specific Shopify collections with Cartr so that you can easily
          access them while livestreaming from Switcher.
        </TextContainer>
      ),
      list: (
        <List type="number">
          <List.Item>
            Click <strong>Manage availability</strong> below.
          </List.Item>
          <List.Item>Choose the collections you’d like to share.</List.Item>
        </List>
      ),
    },
    {
      icon: <img src={CartrTab} alt="Cartr Tab" />,
      title: "Launch Switcher Studio and open the Cartr tab",
      description: (
        <TextContainer>
          Open Switcher Studio on your iOS device, log in, and tap the Cartr tab
          (the shopping cart icon) to access the Shopify products available for
          display in your streams. Simply tap to display!
        </TextContainer>
      ),
      link: (
        <Link url={process.env.REACT_APP_GETTING_STARTED_LINK} external>
          Visit our Help Center article on getting started with Cartr
        </Link>
      ),
    },
  ];
  return (
    <Card title="Quick steps to get you started" sectioned>
      <div style={{ marginBottom: "2rem" }}>
        <Stack wrap={false}>
          <Stack.Item fill>
            <TextStyle>
              Once you’ve connected your Switcher account, follow these steps:
            </TextStyle>
          </Stack.Item>
          <Stack.Item>
            <Button
              textAlign="right"
              size="slim"
              plain
              icon={collapsed ? <DownIcon /> : <UpIcon />}
              onClick={() => setCollapsed(!collapsed)}
            />
          </Stack.Item>
        </Stack>
      </div>
      <Collapsible id="ToDos" open={!collapsed}>
        {toDoItems.map((toDo, key) => (
          <GetStartedChecklistItem toDo={toDo} key={key} />
        ))}
      </Collapsible>
    </Card>
  );
}
