import React, { useEffect, useState } from "react";
import { Banner } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

const defaultTrialDays = parseInt(process.env.REACT_APP_DEFAULT_TRIAL_DAYS);

export interface ChoosePlanBannerProps {
  shouldBeShownByDefault: Boolean;
  onAction?: () => void
}

export const ChoosePlanBanner: React.FC<ChoosePlanBannerProps> = ({
  shouldBeShownByDefault,
  onAction
}) => {
  const [dismissed, setDismissed] = useState<Boolean>(!shouldBeShownByDefault);

  useEffect(() => {
    setDismissed(!shouldBeShownByDefault);
  }, [shouldBeShownByDefault])

  const shopState = useSelector((s: RootState) => s.shop);
  
  if (dismissed) {
    return null;
  }

  return (
    <Banner
      title="Choose a Switcher plan to sell with Cartr!"
      action={{
        content: "View Switcher Plans",
        onAction: onAction
      }}
      status="warning"
      onDismiss={() => setDismissed(true)}
    >
      <p>
        To use Cartr with Shopify, you need to subscribe to Switcher.
        {shopState?.shopInfo?.trialData?.hasTrialed
        ? (
          ``
        )
        : (
          `Every subscription starts with a ${defaultTrialDays}-day free trial. Cancel any time.`
        )}
      </p>
    </Banner>
  );
};
