import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Layout, SkeletonBodyText } from "@shopify/polaris"
import { PlansCard } from 'components/cards/Plans';
import { PlanState } from 'components/cards/Plans/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { useClaimedCoupon } from 'hooks/useClaimedCoupon';
import { ShopState } from 'store/shop/types';

const defaultTrialDays = parseInt(process.env.REACT_APP_DEFAULT_TRIAL_DAYS);

type PlanCopyByPlanState = {
  [k in PlanState]: {
    title: ReactNode | string;
    description: ReactNode | string;
  }
}

export const PlansSection = React.forwardRef ((props, ref) => {
    const [planState, setPlanState] = useState<PlanState>();
    const shopState = useSelector((s: RootState) => s.shop);

    const claimedCoupon = useClaimedCoupon();

    const getActiveProduct = useCallback(() => shopState?.shopInfo?.currentSubscription
      ? shopState?.shopInfo.currentSubscription?.ShopifyProduct
      : shopState?.shopInfo.connectedShopUser?.ActiveProduct, [shopState]);

    const isMerchantPlan = useCallback((shopState: ShopState) => {
      return (
        shopState?.shopInfo?.connectedShopUser?.Roles.includes("Merchant")
      );
    }, []);

    useEffect(() => {
      const productType = shopState?.shopInfo?.currentSubscription
        ? "ShopifyProduct"
        : shopState?.shopInfo?.connectedShopUser?.ActiveProduct?.discriminator;
      const getPlanState = (): PlanState => {
        switch (productType) {
          case "ShopifyProduct":
            return PlanState.SHOPIFY_SUBSCRIPTION;
          case "StripeProduct":
          case "AppleProduct":
          default:
            if (isMerchantPlan(shopState)) {
              return PlanState.STRIPE_MERCHANT_PLAN;
            }
            if (!shopState?.shopInfo?.connectedShopUser || shopState?.shopInfo?.connectedShopUser?.Status !== "trialing") return PlanState.NON_SHOPIFY_SUBSCRIPTION;
            return shopState.shopInfo.connectedShopUser.ActiveProduct.IsActive ? PlanState.TRIAL_SUBSCRIPTION_ACTIVE : PlanState.TRIAL_SUBSCRIPTION_EXPIRED;
        }
      }
      setPlanState(getPlanState());
    }, [isMerchantPlan, shopState])
    const daysLeftOnProduct =  shopState?.shopInfo?.trialData ? shopState.shopInfo.trialData.daysLeftOnTrial : 0;
    const planCopyByPlanState: PlanCopyByPlanState = {
      loading: {
        title: "Plan Overview",
        description: <SkeletonBodyText />,
      },
      nonShopifySubscription: {
        title: "Plan Overview",
        description: (
          <>
            <p>
            To use Cartr with Shopify, you need to subscribe to Switcher.
            {shopState?.shopInfo?.trialData?.hasTrialed
            ? (
              ""
            )
            : (
              `Every subscription starts with a ${defaultTrialDays}-day free trial. Cancel any time.
              Merchants who do not cancel before the free ${defaultTrialDays}-day trial period expires will be automatically billed.`
              )}
            </p>
          </>
          )
      },
      trialSubscriptionActive: {
        title: "Choose a Plan",
        description: (
          <>
            <p>
              You have
              {" "}{daysLeftOnProduct}{" "}
              days left on your trial.
              Choose a plan to continue using Cartr after the trial period. Choosing a plan immediately starts a billing cycle and removes the watermark from future broadcasts.
            </p>
          </>
          )
      },
      trialSubscriptionExpired: {
        title: "Choose a Plan",
        description: (
          <>
            <p>
              Your Cartr trial has expired.
              Choose a plan to continue using Cartr. Choosing a plan immediately starts a billing cycle and removes the watermark from future broadcasts.
            </p>
          </>
          )
      },
      shopifySubscription: {
        title: "Manage Your Plan",
        description: (
          <>
            <p>
              Your monthly subscription to the Switcher
              {" "}{shopState?.shopInfo?.currentSubscription?.ShopifyProduct?.Name}{" "}
              plan gives you access to all of Cartr's tools.
            </p>
          </>
          )
      },
      stripeMerchantPlan: {
        title: "Manage Your Plan",
        description: (
          <>
            <p>
              Your monthly subscription to the Switcher Merchant plan gives you access to all of Cartr's tools.
            </p>
          </>
        )
      }
    }


    return (
        <Layout.AnnotatedSection
          title={planCopyByPlanState[planState]?.title}
          description={planCopyByPlanState[planState]?.description}
          >
          <span ref={ref as React.RefObject<HTMLDivElement>}></span>
          <PlansCard
            currentUserProduct={getActiveProduct()}
            planState={planState}
            key={shopState?.shopInfo?.currentSubscription?.Id}
            claimedCoupon={claimedCoupon}
          />
        </Layout.AnnotatedSection>
    );
})
