import Rollbar from "rollbar";
import { createSlice, } from "@reduxjs/toolkit";

// TODO: configure these somewhere (store?) left here temporarily
const globalEnvironment = process.env.REACT_APP_ENV || "local";
const globalAppVersion = process.env.REACT_APP_VERSION || "1.0";

const config = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN || '73cb2fc0432e423f9a8baaaa3e40e42a',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: globalEnvironment === 'local' ? true : true,
  environment: globalEnvironment,
  payload: {
      environment: globalEnvironment,
      client: {
          javascript: {
              code_version: globalAppVersion,
              source_map_enabled: true,
              guess_uncaught_frames: true
          }
      }
  }
};

export const rollbar = createSlice({
  name: "rollbar",
  initialState: {
    instance: new Rollbar(config)
  },
  reducers: {
    init: (state) => {
      state.instance = new Rollbar(config);
    },
    configureRollbar: (state, { payload }) => {
      state.instance.configure({
        payload: {
          person: {
            id: payload?.UserId
          }
        }
      });
    }
  }
});
export const { init, configureRollbar } = rollbar.actions;

export default rollbar.reducer;
