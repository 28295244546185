
import {
  Banner,
  SkeletonBodyText,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import { Collection } from "hooks/useGetCollections";
import React, { useMemo } from "react";
import { InlineCollectionDisplay } from "./InlineCollectionDisplay"

export interface CollectionsCardContentProps {
  loading: boolean;
  collections: Collection[];
  productCount: number;
  toAdminPage: (path: string) => void;
}

export const CollectionsCardContent: React.FC<CollectionsCardContentProps> = ({
  loading,
  collections,
  productCount,
  toAdminPage
}) => {
  const collectionCount = useMemo(() => collections?.length, [collections]);
  
  if (loading) return <SkeletonBodyText />;

  if (!collections || !collections.length) {
    return (
      <Banner
        title="No collections are available"
        status="warning"
        action={{
          content: "Manage Collections",
          onAction: () => toAdminPage(`/collections`)
        }}
        secondaryAction={{
          content: "How do I change availability?",
          url: process.env.REACT_APP_COLLECTIONS_HELP_LINK,
          external: true,
        }}
      >
        <p>
          Looks like you haven’t yet made any Shopify collections available to
          Cartr. Let’s change that!
        </p>
      </Banner>
    );
  }

  return (
    <Stack spacing="loose" vertical>
      {productCount === 0 && (
        <Banner
          title="No products are available"
          status="warning"
          action={{
            content: "Manage Collections",
            onAction: () => toAdminPage(`/collections`)
          }}
        >
          <TextContainer>
            Right now you have collections available, but no products within
            those collections are available. Let’s change that!
          </TextContainer>
        </Banner>
      )}
      <TextContainer>
        You have <strong>{productCount}</strong> product
        {productCount !== 1 ? "s" : ""} available from{" "}
        <strong>{collectionCount}</strong> collection
        {collectionCount !== 1 ? "s" : ""}
      </TextContainer>
      <Stack vertical>
        {collections.map((collection, key) => (
          <span key={key}>
            <InlineCollectionDisplay collection={collection} />
          </span>
        ))}
      </Stack>
    </Stack>
  );
};
