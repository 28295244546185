import React from "react";
import {
  Card,
  Layout,
  Link,
  EmptyState as EmptyStateBase,
} from "@shopify/polaris";
import styles from "./styles.module.css";

const defaultTrialDays = parseInt(process.env.REACT_APP_DEFAULT_TRIAL_DAYS);

export interface WelcomePageProps {
  connectSwitcherAccount: (showLoginPage?: boolean) => void;
}

export const WelcomePage: React.FC<WelcomePageProps> = ({
  connectSwitcherAccount,
}) => {

  return (
    <Layout.Section>
      <div className={styles["welcome-page-container"]}>
        <Card>
          <EmptyStateBase
            heading="You're one step away from creating amazing shoppable livestreams."
            action={{
              content: "Get started now",
              onAction: () => connectSwitcherAccount(false),
            }}
            image="/assets/icons/Cartr-Icon.jpg"
            largeImage="/assets/desktop/20220126-ShopifyAppStore-ConnectAccountGraphic-Desktop.png"
            footerContent={
              <>
                <p>
                  Already using Switcher? 
                </p>
                <Link
                    onClick={connectSwitcherAccount}
                    removeUnderline
                  >
                    Log in and configure your account
                  </Link> <br />
              </>
            }
            fullWidth
            imageContained
          >
            <p>
                Get {defaultTrialDays} days free trial. Cancel anytime.
            </p>
          </EmptyStateBase>
        </Card>
      </div>
    </Layout.Section>
  );
};
