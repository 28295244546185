import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { CouponResponse, CouponResponseBillingProvider } from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { Plan } from "helpers/subscriptionPlans";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/reducers";
import { ShopConfiguration } from "store/shop/types";
import store from "store/store";
import { setCreatedAppSubscription } from "store/user/slice";
import { AppSubscriptionCreateResponse, PricingType } from "store/user/types"

const defaultTrialDays = parseInt(process.env.REACT_APP_DEFAULT_TRIAL_DAYS);

export const useCreateAppSubscription = (claimedCoupon: CouponResponse) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const dispatch = useDispatch();
    const state = store.getState();
    const shop = state.navigation.shop;
    const shopState = useSelector((s: RootState) => s.shop);

    const trialDays = useMemo<number>(() => {
        if (claimedCoupon?.BillingProvider === CouponResponseBillingProvider.Shopfiy) {
            return claimedCoupon.ResellerInventoryItem.ResellerInventory.TrialDays ?? defaultTrialDays;
        } else if (shopState?.shopInfo?.trialData?.hasTrialed) {
            return 0;
        } else {
            return defaultTrialDays;
        }
    }, [claimedCoupon, shopState])

    const handleCreateApp = useCallback(async (plan: Plan): Promise<void> => {
        const shopDetails: ShopConfiguration = await client.shopify_QueryShopifyAdmin("shop");
        const createAppSubscriptionResponse: AppSubscriptionCreateResponse = await client.shopify_QueryShopifyAdminGraphQL({
          Query: `
            mutation AppSubscriptionCreate($name: String!, $lineItems: [AppSubscriptionLineItemInput!]!, $returnUrl: URL!, $test: Boolean!, $trialDays: Int!){
              appSubscriptionCreate(name: $name, returnUrl: $returnUrl, lineItems: $lineItems, test: $test, trialDays: $trialDays)
              {
                confirmationUrl
                appSubscription {
                    createdAt
                    currentPeriodEnd
                    id
                    name
                    returnUrl
                    status
                    test
                    trialDays
                    lineItems {
                        id
                        plan {
                            pricingDetails {
                                __typename
                                ... on AppRecurringPricing {
                                    interval
                                    price {
                                        amount
                                        currencyCode
                                    }
                                    discount {
                                        durationLimitInIntervals
                                        value
                                    }
                                }
                                ... on AppUsagePricing {
                                    cappedAmount {
                                        amount
                                        currencyCode
                                    }
                                    terms
                                }
                            }
                        }
                    }
                }
              }
            }
          `,
          Variables: {
              name: `${shop} Cartr Subscription`,
              returnUrl: `https://${shop}/admin/apps/${process.env.REACT_APP_SHOPIFY_CLIENTID}/accounts`,
              trialDays,
              test: process.env.REACT_APP_TEST_SHOPIFY_CHARGES === "true" || shopDetails?.shop?.plan_name === "affiliate",
              lineItems: plan.prices.map((price) => {
                switch (price.PlanType) {
                    // RECURRING
                    case 0:
                        return {
                            plan: {
                                appRecurringPricingDetails: {
                                    price: {
                                        amount: plan.price,
                                        currencyCode: "USD",
                                    }
                                }
                            }
                        };
                    // USAGE
                    case 1:
                        return {
                            plan: {
                                appUsagePricingDetails: {
                                    terms: price.Terms,
                                    cappedAmount: {
                                        amount: price.CappedAmount >= 0 ? price.CappedAmount / 100 : 0,
                                        currencyCode: "USD",
                                    }
                                }
                            }
                        }
                    default:
                        return {};
                }
            }),
          }
        });
    
        const getPriceId = (pricingType: PricingType) => {
            switch (pricingType) {
                case "AppUsagePricing":
                    return plan.prices.find((price) => price.PlanType === 1)?.Id;
                case "AppRecurringPricing":
                default:
                    return plan.prices.find((price) => price.PlanType === 0)?.Id;
            }
        }
    
        const mappedLineItems = createAppSubscriptionResponse?.appSubscriptionCreate?.appSubscription.lineItems.map((lineItem) => ({
            ...lineItem,
            priceId: getPriceId(lineItem?.plan?.pricingDetails?.__typename)
        }));
    
        const appSubscriptionWithPriceIds: AppSubscriptionCreateResponse = {
            ...createAppSubscriptionResponse,
            appSubscriptionCreate: {
                ...createAppSubscriptionResponse?.appSubscriptionCreate,
                appSubscription: {
                    ...createAppSubscriptionResponse?.appSubscriptionCreate?.appSubscription,
                    shopId: shop,
                    createdAt: new Date(createAppSubscriptionResponse?.appSubscriptionCreate?.appSubscription?.createdAt).toISOString(),
                    currentPeriodEnd: new Date(createAppSubscriptionResponse?.appSubscriptionCreate?.appSubscription?.currentPeriodEnd).toISOString(),
                    lineItems: mappedLineItems,
                }
            },
        };
    
        dispatch(setCreatedAppSubscription(appSubscriptionWithPriceIds));
    
        await client.shopifySubscriptions_PostShopifySubscription(shop, { ...appSubscriptionWithPriceIds?.appSubscriptionCreate?.appSubscription });
    
        const app = createApp({
            apiKey: process.env.REACT_APP_SHOPIFY_CLIENTID,
            host: state.navigation.host,
        });
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.REMOTE, createAppSubscriptionResponse?.appSubscriptionCreate?.confirmationUrl)
    }, [dispatch, shop, state.navigation.host, trialDays]);

    const createAppSubscription = useCallback(async (plan: Plan): Promise<void> => {
        try {
            setLoading(true);
            await handleCreateApp(plan);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(e);
        }
    }, [handleCreateApp]);

    return {
        createAppSubscription,
        loading,
        error,
    }
}