import React, { useCallback, useMemo, useState } from "react";
import { Heading, Layout, Page, TextContainer } from "@shopify/polaris";
import styles from "./styles.module.css";
import { Plan } from "helpers/subscriptionPlans";
import { ChoosePlanCard } from "./ChoosePlanCard";
import { useCreateAppSubscription } from "hooks/useCreateAppSubscription";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useClaimedCoupon } from "hooks/useClaimedCoupon";
import { CouponInputSection } from "components/sections/CouponInputSection";
import { useGetAvailableShopifyPlans } from "hooks/useGetAvailableShopifyPlans";

const defaultTrialDays = parseInt(process.env.REACT_APP_DEFAULT_TRIAL_DAYS);

export const ChoosePlanPage: React.FC = () => {
  const claimedCoupon = useClaimedCoupon();
  const { loading: shopifyPlansLoading, shopifyPlans } = useGetAvailableShopifyPlans();

  const trialDays = useMemo<number>(
    () =>
      claimedCoupon?.ResellerInventoryItem?.ResellerInventory.TrialDays ?? defaultTrialDays,
    [claimedCoupon?.ResellerInventoryItem]
  );

  const {
    createAppSubscription,
    loading,
  } = useCreateAppSubscription(claimedCoupon);
  const [createdAppSubscriptionType, setCreatedAppSubscriptionType] = useState<Plan["name"]>();

  const handleCreateAppSubscription = useCallback((plan: Plan) => {
    setCreatedAppSubscriptionType(plan.name);
    createAppSubscription(plan);
  }, [createAppSubscription]);

  const shopState = useSelector((s: RootState) => s.shop);

  const userHasSwitcherSubscription = (
    shopState?.shopInfo?.connectedShopUser?.ActiveProduct?.IsActive
    && shopState?.shopInfo?.connectedShopUser?.ActiveProduct?.discriminator !== "ShopifySubscription"
    && shopState?.shopInfo?.connectedShopUser?.Status !== "trialing"
  );

  const filteredShopifyPlans = shopifyPlans?.length
    ? shopifyPlans.filter(plan => plan.prices.some(price => !!price.IsPublic && !!price.Active))
    : [];

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <TextContainer>
            {userHasSwitcherSubscription ? (
              <>
                <Heading>Switch to Shopify</Heading>
                <p>
                  In order to access the full functionality of Cartr alongside
                  Switcher Studio, billing for your Switcher Studio subscription
                  must be done through Shopify. By selecting a plan below, your
                  current subscription will be cancelled and a new subscription
                  will be created and billed through Shopify. Any remaining
                  value from your current subscription will be refunded on a
                  prorated basis.
                </p>
              </>
            ) : (
              <>
                <Heading>Choose your plan</Heading>
                <p>
                  Try Switcher free for{" "}
                  {trialDays !== defaultTrialDays ? (
                    <>
                      <s>{defaultTrialDays}</s> <strong>{trialDays} days </strong>
                    </>
                  ) : (
                    `${defaultTrialDays} days`
                  )}{" "}
                  to access all of our livestreaming and live selling tools. You
                  won’t be charged until after your trial period ends, and you
                  can cancel anytime.
                </p>
              </>
            )}
          </TextContainer>
        </Layout.Section>

        <CouponInputSection claimedCoupon={claimedCoupon} />

        <Layout.Section>
          <div className={styles["plan-cards-container"]}>
            {filteredShopifyPlans.map((plan) => (
              <ChoosePlanCard
                plan={plan}
                createAppSubscription={handleCreateAppSubscription}
                createAppSubscriptionLoading={loading || shopifyPlansLoading}
                createdAppSubscriptionType={createdAppSubscriptionType}
                userHasSwitcherSubscription={userHasSwitcherSubscription}
                trialDays={trialDays}
              />
            ))}
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
