import { useEffect, useState } from "react";
import { DateRange } from "pages/AnalyticsPage/LivestreamsTable";
import { calculateTotals } from "helpers/tables";
import { client } from "api/client";
import { TableData } from "@shopify/polaris";
import { StoreAnalyticsBroadcastSummary } from "@switcherstudio/switcher-api-client";
import { format } from "helpers/currency";

export const useGetLivestreams = (shopId: string, selectedDates: DateRange) => {
  const [livestreams, setLivestreams] = useState<TableData[][]>();

  // const toLivestreamPage = useCallback(
  //   (id: string) => {
  //     const app = createApp({
  //       apiKey: process.env.REACT_APP_SHOPIFY_CLIENTID,
  //       host: state.navigation.host,
  //     });
  //     const redirect = Redirect.create(app);
  //     redirect.dispatch(Redirect.Action.APP, `/analytics/${id}`);
  //   },
  //   [state.navigation.host]
  // );

  useEffect(() => {
    const fetch = async () => {
      const broadcastSummaries =
        await client.shopify_GetShopifyBroadcastAnalyticsByShopId(
          shopId,
          selectedDates.start.toUTCString(),
          selectedDates.end.toUTCString()
        );
      setLivestreams(
        broadcastSummaries.map((livestream: StoreAnalyticsBroadcastSummary) => {
          return [
            livestream.BroadcastName,
            new Date(livestream.BroadcastDate).toLocaleDateString(),
            livestream.UniqueBuyers,
            livestream.TotalItemsSold,
            format(livestream.Currency, livestream.TotalSales),
          ];
        })
      );
    };

    fetch();
  }, [shopId, selectedDates]);

  return {
    loading: !livestreams,
    livestreams,
    totals: calculateTotals(livestreams, [1]),
  };
};
